<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header :big-headline="true" :quickSearch.sync="filters.search">
            <div class="mb-n3">Pricing Rule Labels</div>
            <template slot="actions">
                <mercur-button  data-test="pricingRuleLabelsAddButton" @click="triggerAdd" class="btn btn-raised btn-primary">
                    <span>Add new pricing rule label</span>
                </mercur-button>
                <mercur-button :to="{ 'name': 'PricingRuleLabelMappingFiles' }" class="btn btn-raised btn-primary">
                    <i class="fas fa-upload"></i>
                    <span>Manage mapping files</span>
                </mercur-button>
            </template>
        </grid-header>

        <div class="border mt-3 fill full-height-layout">
            <data-table
                class="fill full-height-layout border"
                ref="grid"
                :options="options"
                :url="url"
                :quickSearch="filters.search"
                data-test="pricingRuleLabelsTable"
            ></data-table>
        </div>

        <mercur-dialog data-test="pricingRuleLabelsDialog" :is-open.sync="isOpen" @close="triggerClose">
            <template slot="default">
                <h2 class="font-weight-normal">{{ draft ? 'Edit pricing rule label' : 'Add new pricing rule label'}}</h2>

                <pretty-select
                    v-if="labelTypes"
                    placeholder="Select type of label"
                    :options="labelTypes"
                    v-model="form.labelType"
                    :disabled="this.isEdit"
                    :on-change="prefillMainLabel()"
                ></pretty-select>

                <mercur-input data-test="pricingRuleLabelsNameInput" v-model.trim="form.labelName" :class="{'form-invalid': $v.form.labelName.$error}">
                    Label name
                    <template slot="note">
                        <span class="form-error" v-if="!$v.form.labelName.required">Label name is required</span>
                    </template>
                </mercur-input>

                <mercur-input v-if="!this.isDynamicLabel()" v-model.trim="form.minGpm" :class="{'form-invalid': $v.form.minGpm.$error}">
                    Min GPM
                    <template slot="note">
                        <span class="form-error" v-if="!$v.form.minGpm.required">Min GPM is required</span>
                        <span class="form-error" v-if="!$v.form.minGpm.decimal">Min GPM is decimal</span>
                        <span class="form-error" v-if="!$v.form.minGpm.between">Min GPM must be between {{$v.form.minGpm.$params.between.min}} and {{$v.form.minGpm.$params.between.max}}</span>
                    </template>
                </mercur-input>

                <div v-if="this.isDynamicLabel()">
                    <pretty-select
                        v-if="mainLabels"
                        placeholder="Select parent label"
                        label="labelName"
                        :options="mainLabels"
                        v-model="form.parentLabelId"
                        :required="$v.form.parentLabelId.required"
                        :reduce="entry => entry.labelId"
                    ></pretty-select>

                    <mercur-input data-test="pricingRuleLabelsDiscountInput" v-model.trim="form.discount" :class="{'form-invalid': $v.form.discount.$error}">
                        Discount
                        <template slot="note">
                            <span class="form-error" v-if="!$v.form.discount.required">Discount is required</span>
                            <span class="form-error" v-if="!$v.form.discount.decimal">Discount is decimal</span>
                            <span class="form-error" v-if="!$v.form.discount.between">Discount must be between {{$v.form.discount.$params.between.min}} and {{$v.form.discount.$params.between.max}}</span>
                        </template>
                    </mercur-input>
                </div>
            </template>
            <template slot="footer">
                <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button data-test="pricingRuleLabelsSaveButton" class="btn btn-raised btn-primary" @click="submit" :disabled="loading">
                    {{ draft ? 'Update label' : 'Add new label'}}
                </mercur-button>
            </template>
        </mercur-dialog>

        <mercur-dialog data-test="pricingRuleLabelsDeleteDialog" :is-open.sync="isDeleteDialogOpen" @close="triggerClose">
            <p>Are you sure you want to remove this label?</p>
            <template slot="footer">
                <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button data-test="pricingRuleLabelsConfirmDeleteButton" class="btn btn-raised btn-primary" @click="remove" :disabled="loading">Delete</mercur-button>
            </template>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import GridHeader from '@/components/GridHeader'
import DataTable from '../../components/DataTable'
import CONFIG from '@root/config'
import { required, decimal, between } from 'vuelidate/lib/validators'
import PrettySelect from '../../components/utils/PrettySelect'
const MAIN_LABEL = 'Main'
const DYNAMIC_LABEL = 'Dynamic'

export default {
    name: 'PricingRuleLabels',
    components: { GridHeader, DataTable, PrettySelect },
    data () {
        return {
            url: CONFIG.API.ROUTES.PRICING.PRICING_RULE_LABELS.LIST,
            filters: {
                search: '',
            },
            options: {
                columns: {
                    'Label Name': { field: 'labelName' },
                    'Type': { field: 'parentLabelId',
                        cellRenderer (params) {
                            return params.value === null ? MAIN_LABEL : DYNAMIC_LABEL
                        },
                    },
                    'Created': { field: 'dateCreated', sortable: true },
                },
                quickSearchColumns: ['labelName'],
                actions: [
                    {
                        text: 'Edit',
                        icon: 'fas fa-edit',
                        disabled: ({ data }) => this.loading,
                        onClick: ({ data }) => this.triggerEdit(data),
                    },
                    {
                        icon: 'fas fa-trash',
                        text: 'Delete',
                        onClick: ({ data }) => this.triggerRemove(data),
                    },
                ],
            },
            isOpen: false,
            isEdit: false,
            loading: false,
            draft: {},
            isDeleteDialogOpen: false,
            form: {
                labelName: '', discount: null, parentLabelId: null, labelType: MAIN_LABEL,
            },
            mainLabels: null,
            labelTypes: null,
        }
    },
    validations () {
        if (this.isDynamicLabel()) {
            return {
                form: {
                    labelName: {
                        required,
                    },
                    discount: {
                        required,
                        decimal,
                        between: between(0, 100),
                    },
                    parentLabelId: {
                        required,
                    },
                },
            }
        }
        return {
            form: {
                labelName: {
                    required,
                },
                minGpm: {
                    required,
                    decimal,
                    between: between(0, 99),
                },
            },
        }
    },
    methods: {
        prefillMainLabel () {
            if (this.isDynamicLabel() && this.form.parentLabelId === null) {
                this.form.parentLabelId = this.mainLabels[0].labelId
            }
        },
        isDynamicLabel () {
            return this.form.labelType === DYNAMIC_LABEL
        },
        triggerAdd () {
            this.draft = null
            this.isOpen = true
        },
        triggerEdit (data) {
            this.draft = JSON.parse(JSON.stringify(data))
            if (this.draft.minGpm !== null) {
                this.draft.minGpm = this.draft.minGpm * 100
            }
            this.form = this.draft
            this.form.labelType = this.form.parentLabelId === null ? MAIN_LABEL : DYNAMIC_LABEL
            this.isOpen = true
            this.isEdit = true
        },
        triggerClose () {
            this.draft = null
            this.isOpen = false
            this.isEdit = false
            this.isDeleteDialogOpen = false
            this.form = { labelName: '' }
        },
        triggerRemove (data) {
            this.draft = JSON.parse(JSON.stringify(data))
            this.isDeleteDialogOpen = true
        },
        remove () {
            let url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_LABELS.DELETE.replace('{labelId}', this.draft.labelId)
            this.loading = true
            this.$api.delete(url).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Label was deleted',
                    type: 'success',
                })
                this.$refs.grid.refreshGrid()
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: (err.data && err.data.exceptions && err.data.exceptions[0]) ? err.data.exceptions[0] : 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        submit () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            let url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_LABELS.CREATE
            if (this.isEdit) {
                url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_LABELS.UPDATE
            }

            this.loading = true
            let payload = this.form

            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: this.isEdit ? 'Label was updated' : 'Label was added',
                    type: 'success',
                })
                this.$refs.grid.refreshGrid()
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
    },
    created () {
        const getMainLabelsAndTypesUrl = CONFIG.API.ROUTES.PRICING.PRICING_RULE_LABELS.GETMAINLABELSANDTYPES
        this.addJob(getMainLabelsAndTypesUrl)
        this.$api.post(getMainLabelsAndTypesUrl).then(({ data }) => {
            this.$set(this, 'mainLabels', data.data.mainLabels)
            this.$set(this, 'labelTypes', data.data.labelTypes)
        }).finally(() => {
            this.finishJob(getMainLabelsAndTypesUrl)
        })
    },
}
</script>
